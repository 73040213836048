.title {
    width: 100%;
    background-image: url("./Images/Background.png");
    image-rendering: pixelated;
    background-repeat: repeat-x;
    background-size: contain;
    display: flex;
    justify-content: center;
    margin-top: 30px;

    .title-image {
        image-rendering: pixelated;
        width: 100%;
        max-width: 66vh;
        object-fit: fill;
    }
}