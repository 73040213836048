.page-content-container {
    width: 100%;
    overflow: hidden;
    flex: 1;
    display: flex;
    flex-direction: column;

    .page-content {
        width: 100%;
        flex: 1;
        overflow: hidden auto;
    }
}